<template lang="pug">
  section#benefit.section.benefit
    div.container
      div.benefit__box
        div.benefit__content.content
          h2.h2.benefit__title.white Who will benefit from using shortened links?
          div.text.benefit__text.white F4.TO is an indispensable tool for professional bloggers, advertising agencies, marketing companies, commercial businesses, and all users who value convenience and optimization.
        div.benefit__img
          div.benefit_img-inner
            img(src="~@/assets/images/benefit-img.png" alt="Benefit image")

</template>

<script>

export default {
  name: 'BenefitBlock'
}

</script>
